/* Import CSS */
import "@css/app.pcss";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import Glide from '@glidejs/glide';

Fancybox.bind("[data-fancybox]", {
  // Your custom options
});



// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}


ready(() => { 
  
  //--------------------------------------------------------------- Preloader
  
  const pageBody = document.body; 
  
  document.querySelectorAll('.preload-page').forEach(pageToPreload => {
    // vanilla JS
     imagesLoaded( '.preload-page', { background: '.preload-background-image' }, function() {
     // images have loaded so lets change the class and fade out the preloader
      pageBody.classList.add('loaded');
      setTimeout(function () {
      pageBody.classList.add('preloader-removed');
      }, 1000);
     });
  })
  
  //--------------------------------------------------------------- Navigation Toggle
 
   const toggleMenu = document.querySelector('.main-menu-toggle');
   const menu = document.querySelector('.mega-menu');
   
   // check that the toggleMenu element exists before we attempt to attach the event listener
   if(typeof(toggleMenu) != 'undefined' && toggleMenu != null){
     
     /* When you click on the button, we call a function that will get the current value of the "aria-expanded" attribute, and invert it. It will do the same for the "hidden" attribute on the menu. When the menu is visible, we'll focus the first link so it gets properly announced. */
     
     
      
     toggleMenu.addEventListener('click', function () {
       
       // The JSON.parse function helps us convert the attribute from a string to a real boolean
       const open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));
       
       // whatever the value of open is set it to the opposite
       toggleMenu.setAttribute('aria-expanded', !open);
       
       // whatever the value of the html element 'hidden' on menu is set it to the opposite
       menu.hidden = !menu.hidden;
       
       // remove any sub mega menu classes when the hamburger is toggled
       menu.classList.remove("mega-menu-who-we-are-active", "mega-menu-what-we-do-active", "mega-menu-news-active", "mega-menu-connect-active", "mega-menu-foundation-active");
       
       pageBody.classList.toggle("noscroll");
       toggleMenu.classList.toggle("is-active");
       
       // if the menu isn't hidden lets open it
       if (!menu.hidden) {
         menu.classList.add('mega-menu-visible');
       } else {
         menu.classList.remove('mega-menu-visible');
       }
       
     });
     
   }
   
   /* Desktop Top level menu */
   
  function openMegaSubMenu(targetMegaSubMenuTarget){
    
    // Set the classes dependent on the item clicked
    if (targetMegaSubMenuTarget == 'who-we-are'){
      
      menu.classList.remove("mega-menu-what-we-do-active", "mega-menu-news-active", "mega-menu-connect-active", "mega-menu-foundation-active");
      menu.classList.add("mega-menu-who-we-are-active");
    
    } else if (targetMegaSubMenuTarget == 'what-we-do'){
      
      menu.classList.remove("mega-menu-who-we-are-active", "mega-menu-news-active", "mega-menu-connect-active", "mega-menu-foundation-active");
      menu.classList.add("mega-menu-what-we-do-active");
    
    } else if (targetMegaSubMenuTarget == 'news'){
      
      menu.classList.remove("mega-menu-who-we-are-active", "mega-menu-what-we-do-active", "mega-menu-connect-active", "mega-menu-foundation-active");
      menu.classList.add("mega-menu-news-active");
    
    } else if (targetMegaSubMenuTarget == 'contact'){
      
      menu.classList.remove("mega-menu-who-we-are-active", "mega-menu-what-we-do-active", "mega-menu-news-active", "mega-menu-foundation-active");
      menu.classList.add("mega-menu-connect-active");
    
    } else if (targetMegaSubMenuTarget == 'foundation'){
      
      menu.classList.remove("mega-menu-who-we-are-active", "mega-menu-what-we-do-active", "mega-menu-news-active", "mega-menu-connect-active");
      menu.classList.add("mega-menu-foundation-active");
    
    }
    
    // Open the menu
    menu.removeAttribute("hidden");
    menu.classList.add('mega-menu-visible');
    pageBody.classList.add("noscroll");
    toggleMenu.classList.add("is-active");
    toggleMenu.setAttribute('aria-expanded', true);
  
  }
   // Top level menu switcher
   
   const megaMenuTriggers = document.querySelectorAll('.mega-menu-trigger'); 
   
   megaMenuTriggers.forEach(megaMenuTrigger => {
     
     megaMenuTrigger.addEventListener('click', (event) => {
       
       // get the data attribute that defines the target mega sub menu
       let targetMegaSubMenu = megaMenuTrigger.getAttribute('data-mega-sub-menu-target');
       
       openMegaSubMenu(targetMegaSubMenu);
       
       event.preventDefault();
       
     });
     
   });
   
   
   
   //--------------------------------------------------------------- Content Warning
   
   // local storage version means the user will only get this once until they clear their cache 
   
   let contentWarning = document.getElementById("content-warning"); 
   
   if (!localStorage.viewedContentWarning){
     setTimeout(function () {
       
       if (contentWarning.length) {
         contentWarning.classList.remove("content-warning-hidden");
       };
       
       localStorage.viewedContentWarning=1
       
     }, 1000);
   };
   
   // Close content warning panel manually
   
   document.querySelectorAll('.content-warning-close').forEach(item => {
   
     item.addEventListener('click', event => {
     
       if (contentWarning.length) {
         contentWarning.classList.add("content-warning-hidden");
       };
       
       return false;
     
     })
   
   })
   
   
   // -------------------------------------------------------- Accessible modals
   
   document.querySelectorAll('[data-modal]').forEach(item => {
   
     item.addEventListener('click', event => {
        
      event.preventDefault();
      
       let targetLink = item.getAttribute('data-modal');
   
       let targetModal = document.getElementById(targetLink);
   
       openModal(targetModal);
   
       return false;
   
     })
   
   })
   
   
   document.querySelectorAll('.modal-trigger').forEach(item => {
    
      item.addEventListener('click', event => {
        
        event.preventDefault();
        
        let targetLink = item.getAttribute('href');
        let targetModalId = targetLink.replace('#', '');
        
        let targetModal = document.getElementById(targetModalId);
        
        openModal(targetModal);
    
        return false;
    
      })
    
    })
   
   function openModal(targetModal) {
    
     targetModal.classList.add("modal-visible");
   
     // lock the body so it doesn't scroll
     pageBody.classList.add("noscroll");
   }
   
   function closeModals() {
   
     // lock the body so it doesn't scroll
     pageBody.classList.remove("noscroll");
   
     let targetModals = document.querySelectorAll('.modal');
   
     targetModals.forEach(function(el) {
       el.classList.remove("modal-visible");
     });
   
   }
   
   document.querySelectorAll('[data-modal-close-trigger]').forEach(item => {
   
     item.addEventListener('click', event => {
   
       closeModals();
   
       return false;
   
     })
   
   })
   
   document.querySelectorAll('.modal-close, .end-modal-close').forEach(item => {
    
      item.addEventListener('click', event => {
    
        closeModals();
    
        return false;
    
      })
    
    })
   
   
   // close modals with esc key
   document.addEventListener('keydown', (event) => {
     if (event.key === 'Escape') {
       closeModals();
     }
   });
   
   
     
     //--------------------------------------------------------------- Glide sliders
     // General Slider
   let generalSliders = document.getElementsByClassName('general-slider');
   
   if (generalSliders.length) {
     
     // Just get the first instance incase multiple elements exist
      let generalSlider = generalSliders[0];
      
     new Glide(generalSlider,
     
     {
       type: 'carousel',
       startAt: 0,
       perView: 1,
       gap: 15,
       peek: 0
     
     }).mount();
   
   }
   
   // Stories Carousel
   
     
  let storiesCarousels = document.getElementsByClassName('stories-carousel');
  
  if (storiesCarousels.length) {
  
  // Loop over storiesCarousels incase there are multiple instances of this slider on one page
  for (var i = 0; i < storiesCarousels.length; i++) {
  
    new Glide(storiesCarousels[i],
    
    {
      type: 'carousel',
      startAt: 0,
      perView: 4,
      gap: 15,
      peek: 100,
      breakpoints: {
        1680: {
          perView: 4
        },
        1560: {
          perView: 4
        },
        1280: {
          perView: 3
        },
        1024: {
          perView: 2,
          peek: 50
        },
        768: {
          perView: 1,
          peek: 30
        }
      }
    
    }).mount();
    
    }
  
  }
   
   
   // Home Stories Preview Carousel
   let homeStoriesCarousels = document.getElementsByClassName('home-stories-carousel');
   
   if (homeStoriesCarousels.length) {
      
      // Just get the first instance incase multiple elements exist
      let homeStoriesCarousel = homeStoriesCarousels[0];
     
     var homeStoriesCarouselGlide = new Glide(homeStoriesCarousel,
     
     {
       type: 'slider',
       rewind: false,
       startAt: 0,
       perView: 1,
       gap: 15,
       peek: {
         before: 0,
         after: 360
         },
       breakpoints: {
         1560: {
           peek: {
           before: 0,
           after: 260
           }
         },
         1280: {
           peek: {
           before: 0,
           after: 160
           }
         },
         1024: {
           peek: {
           before: 0,
           after: 100
           }
         }
       }
     
     });
     
     let homeStoriesCarouselPrevButton = document.getElementById('home-stories-carousel-prev-button');
     
     homeStoriesCarouselGlide.on('run.after', function() {
       // Handler logic ...
       console.log('home carousel mounted');
       // Show the prev button
       homeStoriesCarouselPrevButton.classList.remove("hide-custom-prev-arrow");
       
     })
     
     homeStoriesCarouselGlide.mount()
   
   }
     
   let largePanelSliders = document.getElementsByClassName('large-panel-slider');
    
    if (largePanelSliders.length) {
       
       // Just get the first instance incase multiple elements exist
       let largePanelSlider = largePanelSliders[0];
     
     new Glide(largePanelSlider,
     
     {
       type: 'carousel',
       startAt: 0,
       perView: 1,
       gap: 0,
       peek: 0
     
     }).mount();
   
   }
   
   
   let tenDesertsIntroSliders = document.getElementsByClassName('ten-deserts-intro-slider');
    
    if (tenDesertsIntroSliders.length) {
      
      // Just get the first instance incase multiple elements exist
       let tenDesertsIntroSlider = tenDesertsIntroSliders[0];
       
      new Glide(tenDesertsIntroSlider,
      
      {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        peek: 0,
        autoplay: 1,
        animationDuration: 5000,
        animationTimingFunc: 'linear',
      
      }).mount();
    
    }
    
    
   let tenDesertsQuotesSliders = document.getElementsByClassName('ten-deserts-quotes-carousel');
   
   if (tenDesertsQuotesSliders.length) {
     
     // Just get the first instance incase multiple elements exist
      let tenDesertsQuotesSlider = tenDesertsQuotesSliders[0];
      
     new Glide(tenDesertsQuotesSlider,
     
     {
       type: 'carousel',
       startAt: 0,
       perView: 1,
       gap: 0,
       peek: 0
     
     }).mount();
   
   }
   
   
   let tenDesertsProjectsSliders = document.getElementsByClassName('ten-deserts-projects-carousel');
    
    if (tenDesertsProjectsSliders.length) {
      
      // Just get the first instance incase multiple elements exist
       let tenDesertsProjectsSlider = tenDesertsProjectsSliders[0];
       
      new Glide(tenDesertsProjectsSlider,
      
      {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        peek: 0
      
      }).mount();
    
    }
    
   //------------------------------------------------------------------ Additional story toggle on /stories
   
   const additionalStoryToggles = document.querySelectorAll('.additional-story-toggle');
   
  // Get all the initial stories
  let initialStories = document.querySelectorAll('.initial-story');
  
  // Get all the additional stories
  let additionalStories = document.querySelectorAll('.additional-story');
  
  let additionalStoriesOpen = false;
   
   additionalStoryToggles.forEach(additionalStoryToggle => {
     
     additionalStoryToggle.addEventListener('click', (event) => {
       
      additionalStoryToggle.classList.toggle("additional-story-toggled");
      
      additionalStories.forEach(function (additionalStory) {
        additionalStory.classList.toggle('additional-story-visible');
      });
      
      // Determine the story to scroll to
      
      if (additionalStoriesOpen){
        scrollToStory(initialStories[0]);
        additionalStoriesOpen = false;
      } else {
        scrollToStory(additionalStories[0]);
       additionalStoriesOpen = true;
      }
       
     });
     
   });
   
   function scrollToStory(targetStory){
     setTimeout(function () {
      targetStory.scrollIntoView();
     }, 500);
   }
   /* ------------------------------------------------------------------ Accessible Tabs https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role */
   
   
   
   const tabs = document.querySelectorAll('[role="tab"]');
   const tabList = document.querySelector('[role="tablist"]');
   
   const mobileTabs = document.querySelectorAll('[role="mobile-tab"]');
   
   if(typeof(tabs) != 'undefined' && tabs != null){
     
     // Add a click event handler to each tab
     tabs.forEach((tab) => {
       tab.addEventListener("click", changeTabs);
     });
   
   }
   
   if(typeof(mobileTabs) != 'undefined' && mobileTabs != null){
     
     // Add a click event handler to each tab
     mobileTabs.forEach((mobileTab) => {
       mobileTab.addEventListener("click", changeTabs);
     });
     
   }
   
   // Enable arrow navigation between tabs in the tab list
   let tabFocus = 0;
   
   if(typeof(tabList) != 'undefined' && tabList != null){
     
     tabList.addEventListener("keydown", (e) => {
       // Move right
       if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
         tabs[tabFocus].setAttribute("tabindex", -1);
         if (e.key === "ArrowRight") {
         tabFocus++;
         // If we're at the end, go to the start
         if (tabFocus >= tabs.length) {
           tabFocus = 0;
         }
         // Move left
         } else if (e.key === "ArrowLeft") {
         tabFocus--;
         // If we're at the start, move to the end
         if (tabFocus < 0) {
           tabFocus = tabs.length - 1;
         }
         }
       
         tabs[tabFocus].setAttribute("tabindex", 0);
         tabs[tabFocus].focus();
       }
     });
   }
     
   function changeTabs(e) {
     const target = e.target;
     const parent = target.parentNode;
     const grandparent = parent.parentNode;
     
     // Remove all current selected tabs
     // get the aria controls element of the clicked target
     let controlledElement = target.getAttribute('aria-controls');
     //console.log(controlledElement);
     
     /* OLD METHOD:
     grandparent
     .querySelectorAll('[aria-selected="true"]')
     .forEach((t) => t.setAttribute("aria-selected", false));
     */
     
     grandparent.querySelectorAll('[aria-selected]').forEach(function (t) {
       let itemAriaControl = t.getAttribute('aria-controls');
       //console.log(itemAriaControl);
       if(itemAriaControl == controlledElement){
         t.setAttribute("aria-selected", true);
       } else {
         t.setAttribute("aria-selected", false);
       }
     });
   
     // Set this tab as selected
    target.setAttribute("aria-selected", true);
   
     // Hide all tab panels
     grandparent
     .querySelectorAll('[role="tabpanel"]')
     .forEach((p) => p.setAttribute("hidden", true));
   
     // Show the selected panel
     grandparent.parentNode
     .querySelector(`#${target.getAttribute("aria-controls")}`)
     .removeAttribute("hidden");
     
     // Scroll to the active tab horizontally
     target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
 
    }
   
   
   //--------------------------------------------------------------- Accordions
   
   
     
   document.querySelectorAll('.accordion-content-toggle').forEach(item => {
   
     item.addEventListener('click', event => {
       
       event.preventDefault();
       event.stopPropagation();
         
       let targetLink = item.getAttribute('data-toggle');
       
       let targetSection = document.getElementById(targetLink);
       
       toggleAccordian(targetSection);
       
       item.classList.toggle("accordion-content-toggle-expanded");
       
       
     })
   
   })
   
   
   
   function toggleAccordian(targetSection) {
       
     targetSection.classList.toggle("accordion-content-visible");
     
     
   }
   
   
   //--------------------------------------------------------------- 10 Deserts Project Accordions
   
   /*
   document.querySelectorAll('.ten-deserts-projects-carousel-toggle').forEach(item => {
    
      item.addEventListener('click', event => {
        
        event.preventDefault();
        event.stopPropagation();
          
        let targetLink = item.getAttribute('data-toggle');
        
        let targetSection = item.nextElementSibling;
        
        console.log(targetSection);
        
        toggleTenDesertsProjectFullArticle(targetSection);
        
        item.classList.toggle("ten-deserts-projects-carousel-toggle-expanded");
        
        
      })
    
    })
    
    
    function toggleTenDesertsProjectFullArticle(targetSection) {
         
       targetSection.classList.toggle("ten-deserts-projects-carousel-full-article-visible");
       
       
     }
     */
   
   //--------------------------------------------------------------- Intersection Observer for Foundation sections
   
   
  function showTransparentHero(){
    //console.log('Elvis has ENTERED the building ');
    pageBody.classList.remove('foundation-hide-transparent-hero');
  }
  
  function hideTransparentHero(){
    //console.log('Elvis has LEFT the building ');
    pageBody.classList.add('foundation-hide-transparent-hero');
  }
   
   var lazyLoadImages = [].slice.call(document.querySelectorAll(".foundation-hero-section-transparent"));
   
   const observerOptions = {
     root: null,
     rootMargin: '0px',
     threshold: 0.5,
   };
   
   function observerCallback(entries) {
     entries.forEach((entry) => {
       if (!entry.isIntersecting) {
         hideTransparentHero();
       }
       else {
         showTransparentHero();  
       }
     });
   }
   
   const observer = new IntersectionObserver(observerCallback, observerOptions);
   
   lazyLoadImages.forEach((el) => observer.observe(el));
   
   
   //------------------------------------------------------------------ Load more articles (originally created for the Press section)
  
  // Load Dynamic Data using the Fetch API
  
  function loadDynamicData(dynamicData, dynamicDataHolder, loadingAnimationDiv) {
    
    // our loading animation div
    
    
    fetch(dynamicData).then(function(response) {
      // The API call was successful!
      return response.text();
    }).then(function(html) {
      // lets parse and display our data and run any other animations etc
      
      // Use appendChild to only insert the content of the dynamic-data-content div 
      // Convert the HTML string into a document object
      let parser = new DOMParser();
      let doc = parser.parseFromString(html, 'text/html');
      let parsedDynamicData = doc.getElementById("dynamic-data-content");
      
      
      dynamicDataHolder.appendChild(parsedDynamicData);
      
      let currentPaginationLink = document.getElementById('pagination-next');
      
      let parsedDynamicPaginationLink = doc.getElementById("pagination-next");
      
      // check to see if there is a next page in the pagination
      if (parsedDynamicPaginationLink != null) {
        // Lets update the data-dynamic-url attribute on this pages load more link
        
        // first lets get the value from the parsed data
        let nextPaginationLink = parsedDynamicPaginationLink.getAttribute('data-dynamic-url');
        
        // then we get the current pages pagination link
        currentPaginationLink.setAttribute('data-dynamic-url', nextPaginationLink);
      } else {
        // let's hide the load more link as there are no more pages to show
        currentPaginationLink.classList.add('dynamic-load-more-link-hidden');
      }
      
      // Everythings loaded lets hide our animation
      loadingAnimationDiv.classList.add('dynamic-loading-animation-hidden');
      
      // And ensure we scroll to the correct location the new id="dynamic-data-content" div
      
      let newDynamicDataContentDiv = document.getElementById('dynamic-data-content');
      newDynamicDataContentDiv.scrollIntoView();
      
      }).catch(function(err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
  
  }
  
  // Attach event listeners to '.dynamic-load-more-link' links
  
  document.querySelectorAll('.dynamic-load-more-link').forEach(item => {
  
    item.addEventListener('click', event => {
      
      // We remove the ID from the id="dynamic-data-content" div so there is only ever one instance of it
      let dynamicDataContent = document.getElementById('dynamic-data-content');
      dynamicDataContent.removeAttribute('id');
      
      let dynamicLoadingAnimation = document.getElementById('dynamic-loading-animation');
      dynamicLoadingAnimation.classList.remove('dynamic-loading-animation-hidden');
      
      let targetData = item.getAttribute('data-dynamic-url');
      
      let targetDataHolderID = item.getAttribute('data-holder-id');
      let targetDataHolder = document.getElementById(targetDataHolderID);
      
      loadDynamicData(targetData, targetDataHolder, dynamicLoadingAnimation);
      
      event.preventDefault();
  
    })
  
  })

   
 }); //--------------------------------------------------------------- END doc init
 
 
 
 
 




